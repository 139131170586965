<template>
    <div id="userProfile" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-homemenu></app-homemenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title">
                                    {{ $t("user_userProfile") }}
                                </h3>
                                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                                <span class="kt-subheader__desc">{{ $t("user_profileSubTitle") }}</span>
                            </div>
                        </div>

                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile  kt-portlet--tabs">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-toolbar">
                                                <ul class="nav nav-tabs nav-tabs-space-xl nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand" role="tablist">
                                                    <li class="nav-item">
                                                        <a id="userProfile_profileLink" class="nav-link active" data-toggle="tab" href="#kt_apps_user_edit_tab_profile" role="tab" aria-selected="true">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                                    <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#000000" fill-rule="nonzero"></path>
                                                                </g>
                                                            </svg>
                                                            {{ $t("user_userProfile") }}
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a id="userProfile_socialProfilesLink" class="nav-link" data-toggle="tab" href="#kt_apps_user_edit_tab_socialnetwork" role="tab" aria-selected="false">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>
                                                            {{ $t("user_socialProfiles") }}
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a id="userProfile_companyLink" class="nav-link" data-toggle="tab" href="#kt_apps_user_edit_tab_company" role="tab" aria-selected="false">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000" />
                                                                    <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                                                                    <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" id="Rectangle-2" fill="#000000" opacity="0.3" />
                                                                </g>
                                                            </svg>
                                                            {{ $t("user_company") }}
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a id="userProfile_settingsLink" class="nav-link" data-toggle="tab" href="#kt_apps_user_edit_tab_account" role="tab" aria-selected="false">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {{ $t("user_settings") }}
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a id="userProfile_changePasswordLink" class="nav-link" data-toggle="tab" href="#kt_apps_user_edit_tab_password" role="tab" aria-selected="false">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" id="Path-50" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z" id="Mask" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" id="Mask-Copy" fill="#000000" opacity="0.3"></path>
                                                                </g>
                                                            </svg>
                                                            {{ $t("user_change_password") }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <form action="" method="" @submit.prevent="onSubmit" novalidate="novalidate" autocomplete="off">
                                            <div class="kt-portlet__body">
                                                <div class="tab-content">
                                                    <div class="tab-pane active" id="kt_apps_user_edit_tab_profile" role="tabpanel">
                                                        <div class="kt-form kt-form--label-right">
                                                            <div class="kt-form__body">
                                                                <div class="kt-section kt-section--first">
                                                                    <h3 class="kt-section__title">{{ $t("user_personalDetails") }}</h3>

                                                                    <div class="kt-section__body">
                                                                        <div class="form-group row">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_loginEmail") }}</label>
                                                                            <div class="col-lg-6">
                                                                                <input type="email" autocomplete="off" class="form-control" placeholder="Enter full name" disabled v-model="loginEmail" data-cy="profileVueEmail" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group row validated">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_firstname") }} *</label>
                                                                            <div class="col-lg-6">
                                                                                <input id="userProfile_firstnameInput" autocomplete="off" type="text" class="form-control" v-model="firstname" @keyup="onChangeForm" />
                                                                                <div v-if="!$v.firstname.required" class="invalid-feedback">
                                                                                    {{ $t("error_fieldIsRequired") }}
                                                                                </div>
                                                                                <div v-if="!$v.firstname.minLen" class="invalid-feedback">
                                                                                    {{ $t("error_minLengthMsg", [$v.firstname.$params.minLen.min]) }}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group row validated">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_lastname") }} *</label>
                                                                            <div class="col-lg-6">
                                                                                <input id="userProfile_lastnameInput" autocomplete="off" type="text" class="form-control" v-model="lastname" @keyup="onChangeForm" />
                                                                                <div v-if="!$v.lastname.required" class="invalid-feedback">
                                                                                    {{ $t("error_fieldIsRequired") }}
                                                                                </div>
                                                                                <div v-if="!$v.lastname.minLen" class="invalid-feedback">
                                                                                    {{ $t("error_minLengthMsg", [$v.lastname.$params.minLen.min]) }}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group row">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_nickname") }}</label>
                                                                            <div class="col-lg-6">
                                                                                <input id="userProfile_nicknameInput" autocomplete="off" type="text" class="form-control" v-model="nickname" @keyup="onChangeForm" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group row">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_jobTitle") }}</label>
                                                                            <div class="col-lg-6">
                                                                                <input id="userProfile_jobTitleInput" autocomplete="off" type="text" class="form-control" v-model="jobTitle" @keyup="onChangeForm" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>

                                                                        <h3 class="kt-section__title">{{ $t("user_emails") }}</h3>

                                                                        <div class="form-group row validated">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_workEmail") }}</label>
                                                                            <div class="col-lg-6">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text"><i class="la la-envelope"></i></span>
                                                                                    </div>
                                                                                    <input id="userProfile_workEmailInput" autocomplete="off" type="text" class="form-control" v-model="workEmail" @keyup="onChangeForm" />
                                                                                    <div class="invalid-feedback" v-if="!$v.workEmail.email">
                                                                                        {{ $t("error_pleaseEnterValidEmail") }}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group row validated">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_personalEmail") }}</label>
                                                                            <div class="col-lg-6">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text"><i class="la la-envelope"></i></span>
                                                                                    </div>
                                                                                    <input id="userProfile_personalEmailInput" autocomplete="off" type="text" class="form-control" v-model="personalEmail" @keyup="onChangeForm" />
                                                                                    <div class="invalid-feedback" v-if="!$v.personalEmail.email">
                                                                                        {{ $t("error_pleaseEnterValidEmail") }}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>

                                                                        <h3 class="kt-section__title">{{ $t("user_phoneNumbers") }}</h3>

                                                                        <div class="form-group row">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_workPhoneNumber") }}</label>
                                                                            <div class="col-lg-6">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text"><i class="la la-phone"></i></span>
                                                                                    </div>
                                                                                    <input id="userProfile_workPhoneInput" type="text" autocomplete="off" class="form-control" v-model="workPhone" @keyup="onChangeForm" />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="form-group row">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_workMobileNumber") }}</label>
                                                                            <div class="col-lg-6">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text"><i class="la la-phone"></i></span>
                                                                                    </div>
                                                                                    <input id="userProfile_workMobileInput" type="text" autocomplete="off" class="form-control" v-model="workMobile" @keyup="onChangeForm" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane" id="kt_apps_user_edit_tab_socialnetwork" role="tabpanel">
                                                        <div class="kt-form kt-form--label-right">
                                                            <div class="kt-form__body">
                                                                <div class="kt-section kt-section--first">
                                                                    <h3 class="kt-section__title">{{ $t("user_socialProfiles") }}</h3>

                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("user_linkedin") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_linkedInUrlInput" autocomplete="off" type="text" class="form-control" v-model="linkedInAccountUrl" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("user_facebook") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_facebookUrlInput" autocomplete="off" type="text" class="form-control" v-model="facebookAccountUrl" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("user_twitter") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_twitterAccountInput" autocomplete="off" type="text" class="form-control" v-model="twitterAccountUrl" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("user_instagram") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_instagramAccountInput" autocomplete="off" type="text" class="form-control" v-model="instagramAccountUrl" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane" id="kt_apps_user_edit_tab_company" role="tabpanel">
                                                        <div class="kt-form kt-form--label-right">
                                                            <div class="kt-form__body">
                                                                <div class="kt-section kt-section--first">
                                                                    <h3 class="kt-section__title">{{ $t("user_company") }}</h3>
                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("user_companyName") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_companyNameInput" autocomplete="off" type="text" class="form-control" v-model="companyName" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>

                                                                    <h3 class="kt-section__title">{{ $t("common_address") }}</h3>

                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("common_address") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_companyStreetInput" autocomplete="off" type="text" class="form-control" v-model="street" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("common_city") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_companyCityInput" autocomplete="off" type="text" class="form-control" v-model="city" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("common_state") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_companyStateInput" autocomplete="off" type="text" class="form-control" v-model="state" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("common_postCode") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_companyZipCodeInput" autocomplete="off" type="text" class="form-control" v-model="zipCode" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group row">
                                                                        <label class="col-lg-3 col-form-label">{{ $t("common_country") }}</label>
                                                                        <div class="col-lg-6">
                                                                            <input id="userProfile_companyCountryInput" autocomplete="off" type="text" class="form-control" v-model="country" @keyup="onChangeForm" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="tab-pane" id="kt_apps_user_edit_tab_account" role="tabpanel">
                                                        <div class="kt-form kt-form--label-right">
                                                            <div class="kt-form__body">
                                                                <div class="kt-section kt-section--first">
                                                                    <h3 class="kt-section__title">{{ $t("user_preferences") }}</h3>
                                                                    <div class="kt-section__body">
                                                                        <div class="form-group row">
                                                                            <label class="col-lg-3 col-form-label">{{ $t("user_language") }}</label>
                                                                            <div class="col-lg-6">
                                                                                <select id="userProfile_langSelect" v-model="preferredLanguage" @change="onChangeLanguage($event)" class="form-control m-input m-input--square">
                                                                                    <option value="en">English</option>
                                                                                    <option value="fr">Français</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane" id="kt_apps_user_edit_tab_password" role="tabpanel">
                                                        <div class="kt-form kt-form--label-right">
                                                            <div class="kt-form__body">
                                                                <div class="kt-section kt-section--first">
                                                                    <div class="kt-section__body">
                                                                        <div class="alert alert-outline-brand fade show" role="alert">
                                                                            <div class="alert-icon"><i class="flaticon-questions-circular-button"></i></div>
                                                                            <div class="alert-text">{{ $t("user_password_rule") }}</div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <label class="col-xl-3"></label>
                                                                            <div class="col-lg-9 col-xl-6">
                                                                                <h3 class="kt-section__title kt-section__title-sm">
                                                                                    {{ $t("user_change_password") }}
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group row validated">
                                                                            <label class="col-xl-3 col-lg-3 col-form-label"> {{ $t("user_password") }} </label>
                                                                            <div class="col-lg-9 col-xl-6">
                                                                                <input type="password" autocomplete="off" class="form-control" value="" :placeholder="$t('user_password')" @input="$v.currentPassword.$touch()" v-model="currentPassword" name="currentPassword" @keyup="onChangeForm" id="currentPasswordInput" />
                                                                                <div class="invalid-feedback" v-if="!$v.currentPassword.strongComplexity">
                                                                                    {{ $t("user_password_rule") }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group row validated">
                                                                            <label class="col-xl-3 col-lg-3 col-form-label">New Password</label>
                                                                            <div class="col-lg-9 col-xl-6">
                                                                                <input type="password" autocomplete="off" class="form-control" value="" @input="$v.newPassword.$touch()" :placeholder="$t('user_newPassword')" name="newPassword" id="newPasswordInput" @keyup="onChangeForm" v-model="newPassword" />
                                                                                <div class="invalid-feedback" v-if="!$v.newPassword.strongComplexity">
                                                                                    {{ $t("user_password_rule") }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group form-group-last row validated">
                                                                            <label class="col-xl-3 col-lg-3 col-form-label">Verify Password</label>
                                                                            <div class="col-lg-9 col-xl-6">
                                                                                <input type="password" autocomplete="off" class="form-control" value="" @input="$v.confirmPassword.$touch()" :placeholder="$t('user_confirmpassword')" v-model="confirmPassword" @keyup="onChangeForm" name="confirmPassword" id="confirmPasswordInput" />
                                                                                <div class="invalid-feedback" v-if="!$v.confirmPassword.sameAsPassword">
                                                                                    {{ $t("error_sameaspassword") }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-6 kt-align-right"></div>
                                                    <div class="col-lg-6 kt-align-right">
                                                        <button id="userProfile_saveChangesButton" :disabled="$v.$invalid || !isFormDataChanged" type="submit" class="btn btn-brand kt-margin-r-5">
                                                            <i class="la la-save"></i>
                                                            {{ $t("common_saveChanges") }}
                                                        </button>
                                                        <button id="editSiteCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
    required,
    minLength,
    helpers,
    sameAs,
    email
} from "vuelidate/lib/validators";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            preferredLanguage: this.user().preferredLanguage,
            loginEmail: this.user().loginEmail,
            firstname: this.user().firstName,
            lastname: this.user().lastName,
            nickname: this.user().nickName,
            jobTitle: this.user().jobTitle,
            workEmail: this.user().workEmail,
            personalEmail: this.user().personalEmail,
            workPhone: this.user().workPhone,
            workMobile: this.user().workMobile,
            companyName: this.user().companyName,
            street: this.user().street,
            city: this.user().city,
            state: this.user().state,
            zipCode: this.user().zipCode,
            country: this.user().country,
            linkedInAccountUrl: this.user().linkedInAccountUrl,
            facebookAccountUrl: this.user().facebookAccountUrl,
            twitterAccountUrl: this.user().twitterAccountUrl,
            instagramAccountUrl: this.user().instagramAccountUrl,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            isFormDataChanged: false
        };
    },
    created: function() {
        console.log("Component(Profile)::created() - called");
    },
    mounted: function() {
        console.log("Component(Profile)::mounted() - called");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(Profile)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        firstname: {
            required,
            minLen: minLength(2)
        },
        lastname: {
            required,
            minLen: minLength(2)
        },
        workEmail: {
            email
        },
        personalEmail: {
            email
        },
        currentPassword: {
            strongComplexity: helpers.regex(
                "alpha",
                /^(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[0-9])(?=.*[a-z]).{8,}$/
            )
        },
        newPassword: {
            strongComplexity: helpers.regex(
                "alpha",
                /^(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[0-9])(?=.*[a-z]).{8,}$/
            )
        },
        confirmPassword: {
            sameAsPassword: sameAs("newPassword")
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapGetters(["user"]),
        ...mapActions(["updateUserProfile"]),

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(Profile)::onCancel() - called");
            this.$router.push({
                name: "home",
                params: { fromAction: "cancelButton" }
            });
        },

        onChangeForm() {
            this.isFormDataChanged = true;
        },

        onChangeLanguage(event) {
            console.log("Component(Profile)::onChangeLanguage() - called");
            this.$i18n.locale = event.target.value;
            this.onChangeForm();
        },

        // Function called when user click on the "Save changes" btn
        onSubmit() {
            console.log("Component(Profile)::onSaveChanges() - called");
            const userData = {
                firstName: this.firstname,
                lastName: this.lastname,
                nickName: this.nickname,
                jobTitle: this.jobTitle,
                linkedInAccountUrl: this.linkedInAccountUrl,
                facebookAccountUrl: this.facebookAccountUrl,
                twitterAccountUrl: this.twitterAccountUrl,
                instagramAccountUrl: this.instagramAccountUrl,
                workEmail: this.workEmail,
                personalEmail: this.personalEmail,
                workPhone: this.workPhone,
                workMobile: this.workMobile,
                companyName: this.companyName,
                preferredLanguage: this.preferredLanguage,
                street: this.street,
                zipCode: this.zipCode,
                city: this.city,
                state: this.state,
                country: this.country,
                currentPassword: this.currentPassword,
                newPassword: this.newPassword,
                confirmPassword: this.confirmPassword
            };
            this.updateUserProfile(userData);
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(
            this,
            "EditSite",
            "update",
            this.isFormDataChanged,
            to,
            from,
            next
        );
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
